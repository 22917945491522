"use client";
import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";

const heroVariants = cva(
  " flex shrink-0 items-end text-balance bg-gray-300 pl-10 align-baseline font-default text-white",
  {
    variants: {
      variant: {
        home: "h-hero bg-cover bg-center pb-[62px] pr-[188px] text-Header1 font-[400] max-md:h-[580px] max-md:px-6 max-md:pb-12 max-md:text-Header3",
        pages:
          "h-pageHero bg-cover bg-center pb-[62px]  text-Header2 font-[400] max-md:pb-12 max-md:pl-6 max-md:text-Header4",
        article:
          "h-pageHero bg-transparent bg-contain bg-center  bg-no-repeat pb-[62px] text-Header2 font-[400] max-md:pb-12 max-md:pl-6 max-md:text-Header4 max-sm:bg-cover",
      },
    },
    defaultVariants: {
      variant: "home",
    },
  },
);

export interface HeroProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof heroVariants> {
  asChild?: boolean;
  title?: string;
  imageSrc?: React.ReactNode;
}

const Hero = React.forwardRef<HTMLDivElement, HeroProps>(
  ({ className, variant, asChild = false, imageSrc, title, ...props }, ref) => {
    const Comp = asChild ? Slot : "div";
    const background = imageSrc ? `url(${imageSrc})` : "none";

    return (
      <Comp
        ref={ref}
        style={background ? { backgroundImage: background } : {}}
        className={cn(heroVariants({ variant }), className)}
        {...props}
      >
        {title && <div>{title}</div>}
      </Comp>
    );
  },
);

Hero.displayName = "Hero";

export { Hero, heroVariants };
